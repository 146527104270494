<template>
  <div class="container">
    <div>
      <div class="head">
        <div class="title">商家营业额排名</div>
        <div class="time">
          <span :class="{ active: isActive == n }" v-for="(i, n) in time" :key="n" @click="switchTab(n)">{{ i }}</span>
        </div>
      </div>
    </div>

    <div>
      <div v-for="(i, n) in storeData" :key="n" class="storeIncome">
        <span class="index">{{ n + 1 }} </span>
        <img class="icon" :src="i.businessLogo | thumbSmall" />
        <span class="name">{{ i.businessName }}</span>
        <span class="income">{{ i.money | twoPoint }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: 0,
      time: ['今日', '昨日', '全部'],
      storeData: []
    }
  },
  created() {
    this.switchTab(0)
  },
  methods: {
    switchTab(num) {
      let endTime = ''
      let startTime = new Date(new Date().toLocaleDateString()).getTime()
      if (num === 0) {
        endTime = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000
      } else if (num === 1) {
        endTime = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000
        startTime = new Date(new Date().toLocaleDateString()).getTime() - 3600 * 1000 * 24
      } else {
        startTime = ''
      }
      this.$api.mall
        .mall('getStore', {
          params: {
            endTime: endTime,
            startTime: startTime
          }
        })
        .then(res => {
          this.isActive = num
          this.storeData = res
        })
        .catch(err => {
          if (err.code !== 2004) {
            this.$message.error(err.msg || '操作失败')
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  border-radius: 20px;
  height: 600px;
  .head {
    display: flex;
    justify-content: space-between;
    margin: 22px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: @color-title;
    }

    .time {
      user-select: none;
      span {
        margin-right: 10px;
        line-height: 35px;
        cursor: pointer;
        transition: all 0.3s;
        &:active {
          color: #999;
        }
      }

      .active {
        color: red;
        font-weight: bold;
      }
    }
  }

  .storeIncome {
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;
    .index {
      width: 15px;
    }
    .icon {
      width: 44px;
      height: 44px;
      border-radius: 5px;
      margin: 0 20px 0 10px;
      object-fit: cover;
    }
    .name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .income {
      color: red;
      font-weight: bold;
    }
  }
}
</style>
