<template>
  <div class="container">
    <div class="head">快速入口</div>
    <div class="items">
      <div class="item" v-for="(i, n) in overView" :key="n">
        <span @click="handleTo(i.router, i.params)">
          <bm-icon :icon-class="i.icon" :style="{ color: i.color }"></bm-icon>
        </span>
        <div class="text" @click="handleTo(i.router, i.params)">
          {{ i.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overView: Array
  },
  methods: {
    handleTo(router, params) {
      this.$emit('handleTo', router, params)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  border-radius: 20px;
  height: 150px;

  .head {
    margin-top: 22px;
    margin-left: 30px;
    font-size: 22px;
    font-weight: bold;
    color: @color-title;
  }

  .items {
    display: flex;
    height: 100%;

    .item {
      .pointer;
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 33%;

      .icon {
        width: 44px;
        height: 44px;
      }

      .text {
        // color: @color-primary;
        font-size: 14px;
        line-height: 30px;
        color: #999;
      }
    }
  }
}
</style>
