<template>
  <div class="date_select">
    <!-- <i class="el-icon-caret-left" @click="handleDate(-1)" style="cursor: pointer; padding: 10px"></i> -->
    <span @click="handleSelectDate" style="cursor: pointer; padding: 10px; line-height: 32px">
      <span>{{ initDate.startTime }}</span>
      <span>至</span>
      <span>{{ initDate.endTime }}</span>
    </span>

    <!-- <i class="el-icon-caret-right" @click="handleDate(1)" :style="nextStyle"></i> -->

    <el-dialog
      :close-on-click-modal="false"
      title="选择查询的时间"
      :visible.sync="selectData.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="selectData.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="selectData.show = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const monthTimeStamp = 2592000000
const weekTimeStamp = 604800000
const sixMonthTimeStamp = 15552000001
export default {
  props: {
    dateType: {
      type: String,
      validator: function (value) {
        return ['month', 'week', 'halfYear'].indexOf(value) !== -1
      }
    }
  },
  data(vm) {
    var dt = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    return {
      selectData: {
        show: false,
        time: []
      },
      initDate: {
        startTime: '222',
        endTime: ''
      },
      filterDate: {
        startTime:
          this.dateType === 'halfYear'
            ? new Date(new Date().toLocaleDateString()).getTime() - sixMonthTimeStamp
            : dt.getTime(),
        endTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000
      },
      curDate: new Date().getTime()
    }
  },
  computed: {
    nextStyle() {
      const isSame = this.filterDate.endTime === this.curDate
      return {
        pointerEvents: isSame ? 'none' : 'auto',
        cursor: isSame ? 'auto' : 'pointer',
        padding: '10px',
        color: isSame ? '#909399' : '#000'
      }
    }
  },
  watch: {
    filterDate: {
      handler(nV) {
        this.$emit('get-date', nV)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initDateMethod()
  },
  methods: {
    initDateMethod() {
      let start = new Date(this.filterDate.startTime)
      let end = new Date(this.filterDate.endTime)
      let monthFormat = {
        startTime: start.getFullYear() + '-' + String(start.getMonth() + 1).padStart(2, '0'),
        endTime: end.getFullYear() + '-' + String(end.getMonth() + 1).padStart(2, '0')
      }
      let weekFormat = {
        startTime: String(start.getMonth() + 1).padStart(2, '0') + '-' + String(start.getDate()).padStart(2, '0'),
        endTime: String(end.getMonth() + 1).padStart(2, '0') + '-' + String(end.getDate()).padStart(2, '0')
      }

      this.initDate.startTime = this.dateType === 'month' ? monthFormat.startTime : weekFormat.startTime
      this.initDate.endTime = this.dateType === 'month' ? monthFormat.endTime : weekFormat.endTime
    },
    handleSelectDate() {
      this.selectData.show = true
    },

    confirm() {
      this.filterDate.startTime = this.selectData.time[0]
      this.filterDate.endTime = this.selectData.time[1]
      this.selectData.show = false
      this.initDateMethod()
    },
    handleDate(type) {
      if (type === -1) {
        // 后退
        if (this.dateType === 'week') {
          this.filterDate.startTime = this.filterDate.startTime - weekTimeStamp
          this.filterDate.endTime = this.filterDate.endTime - weekTimeStamp
          return
        }
        let startDate = new Date(this.filterDate.startTime)
        let endDate = new Date(this.filterDate.endTime)
        let day = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()
        let dt = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
        let dt2 = new Date(endDate.getFullYear(), endDate.getMonth() - 1, day)
        this.filterDate.startTime = dt.getTime()
        this.filterDate.endTime = dt2.getTime()
      } else {
        // 前进
        if (this.dateType === 'week') {
          this.filterDate.startTime = this.filterDate.startTime + weekTimeStamp
          this.filterDate.endTime = this.filterDate.endTime + weekTimeStamp
          return
        }
        let startDate = new Date(this.filterDate.startTime)
        let endDate = new Date(this.filterDate.endTime)
        let day = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate()
        let dt = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
        let dt2 = new Date(endDate.getFullYear(), endDate.getMonth() + 1, day)
        this.filterDate.startTime = dt.getTime()
        this.filterDate.endTime = dt2.getTime()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.date_select {
  background: #f5f5f5;
  border-radius: 30px;
}
</style>
