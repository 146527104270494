<template>
  <div class="container">
    <div class="head"><slot></slot></div>

    <div class="items">
      <div class="item" v-for="(i, n) in OverView" :key="n">
        <bm-tween class="number" :value="i.number"></bm-tween>
        <div class="text">{{ i.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    OverView: Array
  }
}
</script>

<style lang="less" scoped>
.container {
  border-radius: 20px;
  height: 150px;

  .head {
    margin-top: 22px;
    margin-left: 30px;
    font-size: 22px;
    font-weight: bold;
    color: @color-title;
  }

  .items {
    display: flex;
    height: 100%;

    .item {
      .pointer;
      align-self: center;
      width: 25%;
      text-align: center;

      .number {
        display: block;
        color: @color-title;
        font-size: 34px;
      }

      .text {
        color: @color-primary;
        font-size: 14px;
      }

      .active {
        color: @color-primary;
      }
    }
  }
}
</style>
