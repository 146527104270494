<template>
  <div class="container">
    <div class="item" v-for="(i, n) in platformData" :key="n" @click="handleClick(i)">
      <bm-icon class="icon" :style="{ color: i.color }" :icon-class="i.icon"></bm-icon>
      <div class="data">
        <bm-tween class="number" :value="i.number"></bm-tween>
        <div class="text">{{ i.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    platformData: Array
  },
  watch: {
    platformData: {
      handler(n) {},
      immediate: true
    }
  },
  methods: {
    handleClick(val) {
      if (val && val.path) {
        this.$router.push(val.path)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 0 20px;
  .item {
    .pointer;
    .trans;
    flex-shrink: 0;
    flex-basis: calc(20% - 20px);
    border-radius: 6px;
    margin-right: 20px;
    display: flex;
    align-items: stretch;
    background: white;
    padding: 16px;
    &:hover {
      .boxShadow(8px);
    }
    .icon {
      width: 44px;
      height: 44px;
      margin-right: 10px;
    }
    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .number {
        font-size: 26px;
        font-weight: bold;
      }
      .text {
        font-size: 16px;
        white-space: nowrap;
        color: #999;
      }
    }
  }
}
</style>
