<template>
  <div class="home">
    <!-- 平台核心数据 -->
    <div class="platformData">
      <comPlatformData :platform-data="platformData"></comPlatformData>
    </div>
    <!-- 快速处理 -->
    <div class="handle">
      <el-card class="item">
        <comOnPending @handleTo="handleTo" :on-pending="pending"></comOnPending>
      </el-card>
      <el-card class="item">
        <comFastEntry @handleTo="handleTo" :over-view="fastEntry"></comFastEntry>
      </el-card>
    </div>
    <!-- 商城趋势和商家趋势 -->
    <div class="tendency">
      <el-card class="platform">
        <comPlatformTender></comPlatformTender>
      </el-card>
      <el-card class="store">
        <comStoreTender></comStoreTender>
      </el-card>
    </div>
    <!-- 总览 -->
    <div class="overview">
      <el-card class="item">
        <comOverView :over-view="commodityOverView">商品总览</comOverView>
      </el-card>
      <el-card class="item">
        <comOverView :over-view="userOverView">用户总览</comOverView>
      </el-card>
    </div>
  </div>
</template>

<script>
import comMixin from './__com__/index'
console.log(comMixin)
export default {
  mixins: [comMixin],
  data() {
    return {
      platformData: [
        {
          number: 0,
          text: '今日销售总额',
          value: 'todayActualPayMoney',
          color: '#ffa000',
          icon: 'iconwallet',
          path: '/statistics/deal'
        },
        {
          number: 0,
          text: '今日订单总数',
          color: '#655bfe',
          value: 'todayOrderCount',
          icon: 'iconfinancial',
          path: '/order/list'
        },
        {
          number: 0,
          text: '平台会员数',
          value: 'totalCount',
          color: '#3d89ff',
          icon: 'iconuser',
          path: '/user/list'
        },
        {
          number: 0,
          text: '门店总数',
          value: 'totalStoreCount',
          color: 'rgb(255, 108, 102)',
          icon: 'iconstore',
          path: '/vendor/vendorMange/list'
        },
        {
          number: 0,
          text: '平台合伙人数',
          value: 'distributionMemberCount',
          icon: 'iconpartner',
          color: '#20e9ca',
          path: '/distribution/distributorMan'
        }
      ],
      commodityOverView: [
        {
          text: '已经下架',
          number: 0,
          value: 'publishOffCount'
        },
        {
          text: '已上架',
          number: 0,
          value: 'publishOnCount'
        },
        {
          text: '库存紧张',
          number: 0,
          value: 'lowStockCount'
        },
        {
          text: '全部商品',
          number: 0,
          value: 'totalProductCount'
        }
      ],
      userOverView: [
        {
          text: '今日新增',
          number: 0,
          value: 'todayCount'
        },
        {
          text: '昨日新增',
          number: 0,
          value: 'yesterdayCount'
        },
        {
          text: '本月新增',
          number: 0,
          value: 'thisMonthCount'
        },
        {
          text: '会员总数',
          number: 0,
          value: 'totalCount'
        }
      ],
      pending: [
        {
          value: 'stockWarnCount',
          icon: 'iconwaring',
          number: 0,
          color: '#ff4550',
          text: '库存预警',
          router: '/product/partnerProduct/normalProductlist/list',
          params: 8
        },
        {
          value: 'storeExpireCount',
          icon: 'icondate',
          number: 0,
          color: '#7f88ff',
          text: '商品即将到期',
          router: '/product/partnerProduct/normalProductlist/list',
          params: 5
        },
        {
          value: 'refundApplyCount',
          icon: 'iconwallet',
          color: '#ffa000',
          number: 0,
          text: '待处理退款申请',
          router: '/financial/refundApply',
          params: ''
        }
      ],
      fastEntry: [
        {
          text: '订单列表',
          color: '#ff6153',
          router: '/order/list',
          icon: 'iconfinancial'
        },
        {
          text: '商品管理',
          color: '#ff6c66',
          router: '/product/partnerProduct/normalProductlist/list',
          icon: 'iconproduct'
        },
        {
          text: '商家管理',
          router: '/vendor/vendorMange/list',
          color: '#779dff',
          icon: 'iconvendor'
        }
      ],
      orderTender: {},
      userTender: {}
    }
  },
  methods: {
    handleTo(router, params) {
      this.$router.push(router)

      this.$router.push({
        path: router,
        query: {
          paramsId: params
        }
      })
    },

    getAllData() {
      this.$api.mall
        .mall('getHomeStatistic')
        .then(res => {
          this.overView(this.userOverView, res.statisticsmemberVo) //会员总览
          this.overView(this.commodityOverView, res.productVO) //商品总览
          this.overView(this.platformData, res.homeTotalVO) //平台数据总览
          this.platformData[2].number = res.statisticsmemberVo.totalCount
          this.overView(this.pending, res.homeAffairVO) //商品总览
        })
        .catch(err => {
          if (err.code === 2004) {
            this.$message.error('无权限查看页面')
          } else {
            this.$message.error(err.msg || '操作失败')
          }
        })
    },

    overView(arr, res) {
      arr.forEach(item => {
        item.number = res[item.value]
      })
    },
    tabName(tab) {
      this.currentTab = tab.componentName
    }
  },
  created() {
    this.getAllData()
  }
}
</script>

<style lang="less" scoped>
.platformData {
  display: flex;
}

.overview,
.handle {
  .item {
    display: inline-block;
    margin: 0 20px 20px;
    width: calc(50% - 30px);
    &:first-child {
      margin-right: 0;
    }
  }
}
.handle {
  margin: 20px 0 -20px 0;
}

.tendency {
  .platform {
    margin: 20px 0 20px 20px;
    width: calc(100vw - 700px);
    display: inline-block;
  }
  .store {
    margin: 20px;
    width: 420px;
    display: inline-block;
  }
}
</style>
