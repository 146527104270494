<template>
  <div class="container">
    <div class="head">待处理</div>
    <div class="items">
      <div class="item" v-for="(i, n) in OnPending" :key="n">
        <span @click="handleTo(i.router, i.params)">
          <bm-icon :icon-class="i.icon" :style="{ color: i.color }"></bm-icon>
        </span>
        <el-tooltip v-if="n === 0" class="item" effect="dark" content="包含普通商品和外接商品" placement="right">
          <div class="data" @click="handleTo(i.router, i.params)">
            <bm-tween class="number" :value="i.number"></bm-tween>
            <div class="text">{{ i.text }}</div>
          </div>
        </el-tooltip>
        <div v-else>
          <div class="data" @click="handleTo(i.router, i.params)">
            <bm-tween class="number" :value="i.number"></bm-tween>
            <div class="text">{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    OnPending: Array
  },
  methods: {
    handleTo(router, params) {
      this.$emit('handleTo', router, params)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  border-radius: 20px;
  height: 150px;

  .head {
    margin-top: 22px;
    margin-left: 30px;
    font-size: 22px;
    font-weight: bold;
    color: @color-title;
  }

  .items {
    display: flex;
    height: 100%;

    .item {
      .pointer;
      align-self: center;
      width: 33%;
      display: flex;
      align-items: center;

      .icon {
        float: left;
        width: 44px;
        height: 44px;
      }

      .data {
        float: left;
        margin-left: 10px;
        margin-right: -15px;
        display: block;
        .number {
          font-size: 24px;
          font-weight: bold;
        }

        .text {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
}
</style>
