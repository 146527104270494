<template>
  <div class="container" v-loading="loading">
    <div class="head">
      <p class="title">平台趋势图</p>
      <div style="display: flex; justify-content: flex-end">
        <div class="select" v-if="$store.getters['user/isAllArea']">
          <el-select
            class="city area_select"
            placeholder="请选择城市"
            @change="getData"
            v-model="areaId"
            style="width: 120px"
          >
            <el-option label="全国" :value="-1"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <DateSelect ref="dateSelect" dateType="week" @get-date="handleSetDate" />

        <!-- <div class="date_select">
          <span>{{ new Date().getTime() }}</span>
          <span>至</span>
          <span>{{ new Date().getTime() }}</span>
        </div> -->
      </div>
    </div>
    <div class="tabList">
      <div
        v-for="(i, n) in tabList"
        :key="n"
        class="componentTab"
        :class="{ active: currentTab === i }"
        @click="handleChangeTab(i)"
      >
        {{ i }}
      </div>
    </div>

    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import DateSelect from './deteSelect.vue'

export default {
  data() {
    return {
      areaId: -1,
      tabList: ['订单', '用户'],
      currentTab: '订单',
      chartInstance: null,
      orderTenderData: [],
      userTenderData: [],
      loading: false,
      defaultOption: {
        color: this.$store.getters['echarts/colorList'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#fff'
            }
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ]
      },
      useCustom: false,
      filterDate: {
        startTime: new Date().getTime() - 2626560000,
        endTime: new Date().getTime()
      }
    }
  },
  components: {
    DateSelect
  },
  computed: {
    orderChartOption() {
      return {
        ...this.defaultOption,
        xAxis: {
          type: 'category',
          data: this.orderTenderData.map(i => i.time)
        },
        yAxis: [
          {
            name: '金额',
            type: 'value'
          },
          {
            name: '时间',
            type: 'value',
            inverse: true
          },
          {
            name: '订单数',
            position: 'right'
          }
        ],
        legend: {
          data: ['金额', '商家收销售额', '平台收销售额', '退款金额', '实际支付金额', '订单数'],
          selected: {
            商家收销售额: false,
            平台收销售额: false,
            退款金额: false,
            实际支付金额: false,
            订单数: false
          }
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '金额',
            smooth: true,
            data: this.orderTenderData.map(i => i.sellMoney),
            type: 'line',
            yAxisIndex: 0,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(231,76,60,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(231,76,60,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '订单数',
            smooth: true,
            data: this.orderTenderData.map(i => i.orderCount),
            type: 'line',
            yAxisIndex: 2,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(26,188,156,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(26,188,156,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '平台收销售额',
            smooth: true,
            data: this.orderTenderData.map(i => i.platformShareMoney),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(52,152,219,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(52,152,219,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '退款金额',
            smooth: true,
            data: this.orderTenderData.map(i => i.refundMoney),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(155,89,182,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(155,89,182,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '商家收销售额',
            smooth: true,
            data: this.orderTenderData.map(i => i.businessShareMoney),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(46,204,113,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(46,204,113,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '实际支付金额',
            smooth: true,
            data: this.orderTenderData.map(i => i.actualPayMoney),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(52,73,94,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(52,73,94,0.1)'
                }
              ])
            }
          }
        ]
      }
    },
    userChartOption() {
      return {
        ...this.defaultOption,
        xAxis: {
          type: 'category',
          data: this.userTenderData.map(i => i.time)
        },
        yAxis: [
          {
            name: '总用户',
            type: 'value'
          },
          {
            name: '时间',
            type: 'value',
            inverse: true
          }
        ],
        legend: {
          data: ['新增用户', '总用户', '下单用户']
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '新增用户',
            smooth: true,
            data: this.userTenderData.map(i => i.newMemberCount),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(231,76,60,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(231,76,60,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '总用户',
            smooth: true,
            data: this.userTenderData.map(i => i.totalMemberCount),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(26,188,156,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(26,188,156,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '下单用户',
            smooth: true,
            data: this.userTenderData.map(i => i.payMemberCount),
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(26,188,156,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(26,188,156,0.1)'
                }
              ])
            }
          }
        ]
      }
    }
  },
  methods: {
    handleSetDate(date) {
      this.filterDate = date
      this.useCustom = true
      this.getData()
      // if (this.currentTab === '订单') {
      //   this.getOrderData()
      // } else {
      //   this.getUserData()
      // }
    },
    handleChangeTab(val) {
      this.currentTab = val
      this.getData()
    },
    getOrderData() {
      return this.$api.mall
        .mall('getHomeTender', {
          params: {
            queryType: 0,
            startTime: this.useCustom ? this.filterDate.startTime : new Date().getTime() - 3600 * 1000 * 24 * 30,
            endTime: this.useCustom ? this.filterDate.endTime : new Date().getTime()
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.orderTenderData = res
          this.$nextTick(() => {
            this.chartInstance.setOption(this.orderChartOption)
          })
          return
        })
    },
    getUserData() {
      return this.$api.mall
        .mall('getHomeTender', {
          params: {
            queryType: 1,
            startTime: this.useCustom ? this.filterDate.startTime : new Date().getTime() - 3600 * 1000 * 24 * 30,
            endTime: this.useCustom ? this.filterDate.endTime : new Date().getTime()
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.userTenderData = res
          this.$nextTick(() => {
            this.chartInstance.setOption(this.userChartOption)
          })
          return
        })
    },
    getData() {
      this.loading = true
      Promise.resolve()
        .then(() => {
          this.chartInstance.clear()
          if (this.currentTab === '订单') {
            return this.getOrderData()
          } else {
            return this.getUserData()
          }
        })
        .catch(err => {
          if (err.code === 2004) {
            this.$message.error('无权限查看页面')
          } else {
            this.$message.error(err.msg || '操作失败')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1) {
      this.areaId = -1
    } else if (
      this.$store.getters['user/currentAreaId'] == -1 &&
      this.$store.getters['user/userAreaInfoListWithoutGlobal']
    ) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chart)
    this.getData()
  }
}
</script>

<style lang="less">
.area_select {
  width: 70px !important;
  background: #f5f5f5;
  padding-left: 10px;
  border-radius: 20px;
  margin-right: 20px;
  .el-input__inner {
    background: #f5f5f5;
    text-align: left;
    padding: 0;
    border: none;
    border-radius: 20px;
  }
  .el-input__prefix {
    display: none;
  }
}
</style>
<style lang="less" scoped>
.container {
  border-radius: 20px;
  height: 600px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    margin-left: 30px;
    color: @color-title;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    .select {
      font-size: 14px;
    }
  }
}
.tabList {
  margin: 20px 30px;
  .componentTab {
    display: inline-block;
    width: 80px;
    .trans;
    .pointer;
    &.active {
      color: @color-primary-select;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.chart {
  height: 500px;
}
.tab {
  margin-top: 40px;
}
</style>
